import React from 'react'

import Layout from '../../components/layout'
import SessionStorageInput from '../../components/sessionStorageInput'
import Wizard from '../../components/wizard'

const PrototypePage = () => (
  <Layout>
    <Wizard title="Create organization" nextTo="/onboarding-1/choose-template">
    <form>
      <div className="form-group">
        <label htmlFor="org-name">Organization name</label>
        <SessionStorageInput className="form-control" name="org-name" />
      </div>
    </form>
    </Wizard>
  </Layout>
)

export default PrototypePage
